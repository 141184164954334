<template>
    <v-card class="secondary" flat>
        <v-tabs v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
        
            <v-tab href="#details">
                Detalhes
            </v-tab>

            <v-tab href="#subscriptions">
                Subscrições
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item value="details">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-alert
                                color="#171717"
                                border="left"
                                colored-border
                                flat
                                class="body-2"
                                icon="mdi-calendar-heart"
                            >
                                <span>Detalhe de Festival.</span>
                            </v-alert>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-img 
                                v-if="item.cartaz" 
                                :src="item.cartaz" height="300" 
                                contain aspect-ratio="1.4"></v-img>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Nome"
                                placeholder="Nome"
                                :value="item.name"
                                readonly
                                dense
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col>
                            <v-switch readonly 
                                :value="item.status" 
                                :label="`${item.status ? 'Ativo' : 'Desativado'}`"></v-switch>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <label>Data Limite de Registo</label>
                            <v-date-picker
                                :value="item.registerDateLimit"
                                readonly
                                full-width
                                :landscape="$vuetify.breakpoint.smAndUp"
                                class="mt-4"></v-date-picker>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col class="d-flex" cols="12" sm="6">
                            <v-text-field
                                label="Categoria"
                                placeholder="Categoria"
                                :value="item.category.name"
                                readonly
                                dense
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12">
                            <v-select
                                :value="item.allowedProjects"
                                :items="item.allowedProjects"
                                label="Tipos de Obras Permitidas"
                                item-text="name"
                                item-value="id"
                                readonly
                                chips
                                return-object
                                single-line
                                multiple
                                dense
                                outlined>
                                <template v-slot:item="{ item: {title}}">
                                    {{title}}
                                </template>    
                            </v-select>
                        </v-col>
                    </v-row>  

                    <v-row>
                        <v-col cols="12">
                            <p>Descrição:</p><br>
                            <div  v-html="item.description">
                            </div>
                        </v-col>
                    </v-row>
        
                </v-container>
            </v-tab-item>

            <v-tab-item value="subscriptions">
                <v-card flat class="ml-3 mr-3">
                    <Subscriptions :asOwner="true" />
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>
<script>
import Subscriptions from './Subscriptions';

export default {
    components: { Subscriptions },
    props: {
        item: Object,
    },
    data() {
        return {
            tab: null,
        }
    }
}
</script>