<template>
    <div>
        <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            v-if="mySubscriptions.length == 0"
        >
            Lamentamos, mas ainda não tem nenhuma subscrição em festivais.
        </v-alert>
        <v-expansion-panels popout v-else>
            <v-expansion-panel v-for="sub in mySubscriptions" :key="sub.id" hide-actions>
                <v-expansion-panel-header>
                    <v-row align="center" class="spacer" no-gutters>
                    <v-col cols="4" sm="2" md="1">
                        <v-avatar size="36px">
                        <img
                            v-if="sub.festival.src"
                            alt="Avatar"
                            :src="sub.festival.src"
                        >
                        </v-avatar>
                    </v-col>

                    <v-col class="hidden-xs-only" sm="5" md="3">
                        <strong v-html="sub.festival.name"></strong>
                    </v-col>

                    <v-col class="text-no-wrap" cols="5" sm="3">
                        <strong v-html="sub.project.name"></strong>
                    </v-col>

                    <v-col
                        v-if="sub.requestDate"
                        class="grey--text text-truncate hidden-sm-and-down"
                    >
                        &mdash;
                        {{ sub.requestDate }}
                    </v-col>
                    </v-row>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col cols="6">
                            <v-card dark flat class="elevation-0">
                               <div class="d-flex flex-no-wrap justify-space-between">
                                    <v-avatar class="ma-3" size="125" tile>
                                        <v-img :src="asOwner ? sub.project.coverPic : sub.festival.src"></v-img>
                                    </v-avatar>

                                    <div>
                                        <v-card-title
                                            class="headline"
                                            v-text="!asOwner ? sub.festival.name : sub.project.name"
                                        ></v-card-title>
                                        <v-card-subtitle v-text="asOwner ? sub.project.category :sub.festival.category"></v-card-subtitle>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>

                        <v-col cols="6">
                            <v-simple-table >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Data Submissão</th>
                                            <th class="text-left"> {{ !asOwner ? 'Data Validação': 'Autor' }} </th>
                                            <th class="text-left"> {{ !asOwner ? 'Data Inicio' : 'Endereço Email' }} </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ sub.requestDate }}</td>
                                            <td>{{ !asOwner ? sub.festival.validationDate
                                                || sub.status : sub.project.author.firstName + ' ' +  sub.project.author.lastName }}</td>
                                            <td>{{ !asOwner ? sub.festival.startingDate : sub.project.author.email  }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>

                        <v-col cols="6">
                            <v-card max-width="375" class="mx-auto elevation-0" flat>
                                <v-list two-line v-if="!asOwner">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-account-supervisor-circle</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                        <v-list-item-title>
                                            {{ sub.festival.creator.firstName + ' ' + sub.festival.creator.lastName }} 
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Organizadora</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-email</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                        <v-list-item-title> {{ sub.festival.creator.email }} </v-list-item-title>
                                        <v-list-item-subtitle>Endereço Email</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-map-marker</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title> {{ sub.festival.location }} </v-list-item-title>
                                            <v-list-item-subtitle>Localização do Festival</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <div v-else>
                                    <v-card-title
                                        class="body-2"
                                        v-if="sub.project.sinopse"
                                        v-html="sub.project.sinopse"
                                    ></v-card-title>
                                    <v-card-title
                                        class="body-1"
                                        v-if="sub.project.bio"
                                        v-html="sub.project.bio"
                                    ></v-card-title>
                                </div>
                            </v-card>
                        </v-col>

                        <v-col cols="6">
                            <v-simple-table v-if="!asOwner">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Projeto</th>
                                            <th class="text-left">Categoria</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ sub.project.name }}</td>
                                            <td>{{ sub.project.category  }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-row v-else>
                                <v-col cols="12">
                                    <AwardsTables :items="sub.project.awards" :headers="awardsHeader" />
                                </v-col>
                                <v-col cols="12">
                                    <CreditsTable :items="sub.project.credits" :headers="creditsHeader" />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-btn
                            absolute
                            dark
                            bottom
                            right
                            class="elevation-0"
                            :color="`${ sub.status == 'PENDDING' ? 'primary' : 'green' }`"
                            v-if="!asOwner"
                        >
                            <span class="black--text">{{ sub.status }}</span>
                        </v-btn>
                        <v-col cols="6"></v-col>
                        <v-col cols="4" v-if="asOwner">
                            <v-select
                                absolute
                                dark
                                bottom
                                right
                                :items="items"
                                label="Outlined style"
                                item-text="status"
                                item-value="status"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="2" v-if="asOwner">
                            <v-btn dark class="elevation-0" color="primary">
                                <span class="black--text">Submeter</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>


<script>
import { mapState } from 'vuex';
import CreditsTable from './SimpleTable';
import AwardsTables from './SimpleTable';

export default {
    components: {
        CreditsTable,
        AwardsTables
    },
    props: {
        //* This means that we're viewing the subscription as the person who subscribed to the festival or not
        asOwner: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        panel: [0, 1],
        disabled: false,
        readonly: false,
        awardsHeader: [
            {
                text: 'Prémios',
                align: 'center',
                sortable: false,
                value: 'name',
            }
        ],
        creditsHeader: [
            {
                text: 'Créditos',
                align: 'center',
                sortable: false,
                value: 'name',
            }
        ],
        items: [
            {status: 'Pendente'},
            {status: 'Aprovado'},
            {status: 'Rejeitado'},
        ],
    }),

    computed: mapState('festival/festival', ['mySubscriptions'])
}
</script>