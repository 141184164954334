<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="3"
        class="elevation-0"
  ></v-data-table>
</template>

<script>
export default {
    props: ['headers', 'items']
}
</script>