<template>
    <v-container>
        <v-row class="fill-height" align-content="center" justify="center" v-if="loading">
            <v-col class="subtitle-1 text-center" cols="12">Carregando detalhes do seu festival...</v-col>
            <v-col cols="6">
                <v-progress-linear indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
        <Detail v-if="festival != null" :item="festival"/>
    </v-container>
    
</template>
<script>
import Detail from '../../../../components/dashboard/festival/festival/Detail';
import { mapState, mapActions } from 'vuex';
import { HOME } from '../../../../routes';
export default {
    components: {
        Detail
    },
    data() {
        return {
            
        }
    },
    created() {
        
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchDetail();
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState('dashboard/myFestival', ['festival', 'loading'])
    },
    methods: {
        ...mapActions({
            detail: 'dashboard/myFestival/detail',
            subscriptionsByFestival: 'festival/festival/subscriptionsByFestival'
        }),
        fetchDetail() {
            this.detail({id: this.id,});
            this.subscriptionsByFestival({ festival: this.id });
        },
    },
}
</script>